document.addEventListener('DOMContentLoaded', () => {
  toggleMobileMenu()
  toggleActiveStatesOfLinksAndCorrespondingContent()
});

function toggleActiveStatesOfLinksAndCorrespondingContent() {
  const dropdownNavLinks = document.querySelectorAll('.dropdown-nav-link');
  const dropdownNavContents = document.querySelectorAll('.dropdown-nav-content');

  dropdownNavLinks.forEach(dropdownNavLink => {
    dropdownNavLink.addEventListener('click', () => {
      const isActive = dropdownNavLink.classList.contains('active-navigation-link');
      toggleChevron(dropdownNavLink)
      resetChevronsForOtherLinks(dropdownNavLinks, dropdownNavLink)

      dropdownNavLinks.forEach(link => {
        link.classList.remove('active-navigation-link');
      });

      dropdownNavContents.forEach(content => {
        content.classList.add('hidden');
      });

      if (!isActive) {
        dropdownNavLink.classList.add('active-navigation-link');
        const dataAttribute = dropdownNavLink.dataset.content;
        const correspondingContent = document.querySelector(`.dropdown-nav-content[data-content="${dataAttribute}"]`);
        if (correspondingContent) {
          correspondingContent.classList.remove('hidden');
        }
      }
    });
  });

}

function toggleChevron(dropdownNavLink) {
  const chevronDown = dropdownNavLink.querySelector('.chevron-down');
  const chevronUp = dropdownNavLink.querySelector('.chevron-up');
  if (chevronDown && chevronUp) {
    chevronDown.classList.toggle('hidden');
    chevronUp.classList.toggle('hidden');
  }
}

function resetChevronsForOtherLinks(dropdownNavLinks, selectedLink) {
  dropdownNavLinks.forEach(otherLink => {
    if (otherLink !== selectedLink) {
      const otherChevronUp = otherLink.querySelector('.chevron-up');
      const otherChevronDown = otherLink.querySelector('.chevron-down');
      
      if (otherChevronDown && otherChevronUp) {
        otherChevronDown.classList.remove('hidden');
        otherChevronUp.classList.add('hidden');
      }
    }
  });
}

function toggleMobileMenu() {
  const mobileMenuButton = document.querySelector('#mobile-menu-button');
  const allDropdownNavLinks = document.querySelector('.all-dropdown-nav-links');

  mobileMenuButton.addEventListener('click', () => {
    allDropdownNavLinks.classList.toggle('hidden');
  }); 
}